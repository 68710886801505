import * as React from "react"
import { graphql } from "gatsby"
// import { useQuery } from "react-apollo"
import Layout from "../../components/layout"
import SeoBasic from "../../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import ReactHtmlParser from "react-html-parser"
import ScrollerFooter from "../../components/homepage/ScrollerFooter"
import RandomQueryName from "../../hooks/random/randomName"
import RandomQueryDate from "../../hooks/random/randomDate"
// import CaseStudyItemSmall from "../components/caseStudyItemSmall"
import Contact from "../../components/contact"
import Investment from "../../components/investment"
// import ScrollerMobile from "../components/homepage/ScrollerMobile"
import getImage from "../../functions/getImage"


export const query = graphql`
query($slug: String) {
  allWpMediaItem(
    filter: {categories: {nodes: {elemMatch: {name: {eq: "scroller_hompage_footer"}}}}}
    sort: {fields: dateGmt, order: ASC}
  ) {
    edges {
      node {
        id
         mediaItemLink {
          url
        }
        categories {
          nodes {
            id
          }
        }
        mediaItemUrl
        dateGmt
      }
    }
  }
  wpPage(slug: {eq: $slug}) {
    title
    landingpage {
      headerimage {
        mediaDetails {
          sizes {
            name
            sourceUrl
            width
          }
        }
      }
      header1
      header2
      paragraph1
      paragraph2
      paragraph3
    }
  }
  seoPage:wpPage(slug: {eq: $slug}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
    }
}
`

const GetRandomCaseStudies = ({mobile}) => {

  const randomNum = Math.round(Math.random()*10)
  // console.log(randomNum)

  if(randomNum>5){
    // console.log('1=====')
    return <RandomQueryName mobile={mobile} />
  }
  else {
    // console.log('2=====')
    return <RandomQueryDate mobile={mobile} />
  }

}

const IndexPage = ({
  data: {
    allWpMediaItem, wpPage, seoPage
  },
}) => {

  console.log(allWpMediaItem)


  const { landingpage } = wpPage

  return(
  <Layout>
    {seoPage ?
      <Seo post={seoPage} />
    :
      <SeoBasic title="Landing Page" />
    }
    <div header1={landingpage.header1} header2={landingpage.header2} className="landing-page" style={{backgroundColor:"#2F2F2F"}}>

    {landingpage.headerimage &&
    <div>
      <Investment headerImage={getImage(landingpage.headerimage.mediaDetails.sizes)} header1={landingpage.header1} header2={landingpage.header2} />
    </div>
    }

    <div className="section1">
      {ReactHtmlParser(landingpage.paragraph1)}
    </div>

    <div className="section2">
    
    <div className="copy2">{ReactHtmlParser(landingpage.paragraph2)}</div>

    <div className="financing">

        {ReactHtmlParser(landingpage.paragraph3)}

        {typeof window !== "undefined" && window.innerWidth < 600 && 
        <div className="scroller-mobile-landing">
          
          <GetRandomCaseStudies mobile={true} />

        </div>
        }

    </div>

    {typeof window !== "undefined" && window.innerWidth > 600 && 
     <div className="cs-imagesholder">
      <div className="search-results">
        <GetRandomCaseStudies mobile={false} />
      </div>
    </div>
    }

    </div>

    <div>
    <Contact />
    </div>

    <div className="scroller_footer" style={{backgroundColor:`#ffffff`}}>

      {allWpMediaItem &&
      <ScrollerFooter items={allWpMediaItem.edges} />
      }

    </div>

    </div>
   
  </Layout>
)
}

export default IndexPage
