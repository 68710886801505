import * as React from "react"
import { Link } from "gatsby"

const IndexPage = ({headerImage,header1,header2}) => (

     <div className="investment" style={{ backgroundImage: `url("${headerImage}")` }}>

        <h2>{header1}</h2>
        <h3>{header2}</h3>

        <Link activeClassName="active" to="/contact-us/"><button style={{backgroundColor:`#FE9F22`, color:`#fff`}}>CONTACT</button></Link>

      </div>
)

export default IndexPage
